import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { PinContainer } from "../ui/3d-pin";
import { Container } from "./common/Container";
import SwirlyDoodle from "./common/SwirlyDoodle";
import { useNavigate } from "react-router-dom";
import {
  ArrowUpRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";

import TeamImg from "../assets/team.png";
import TeamImgTwo from "../assets/team2.jpg";
import GoogleIcon from "../assets/google.svg";
import InstagramIcon from "../assets/instagram.svg";
import LinkedinIcon from "../assets/linkedin.svg";

const teamMembers = [
  {
    id: 1,
    name: "Rifaqat Ali Khan",
    position: "CEO",
    imgSrc: TeamImg,
    socialLinks: {
      google: GoogleIcon,
      instagram: InstagramIcon,
      linkedin: LinkedinIcon,
    },
  },
  {
    id: 2,
    name: "Sarah Connor",
    position: "Graphics Designer",
    imgSrc: TeamImgTwo,
    socialLinks: {
      google: GoogleIcon,
      instagram: InstagramIcon,
      linkedin: LinkedinIcon,
    },
  },
  {
    id: 3,
    name: "John Doe",
    position: "Developer",
    imgSrc: TeamImg,
    socialLinks: {
      google: GoogleIcon,
      instagram: InstagramIcon,
      linkedin: LinkedinIcon,
    },
  },
  {
    id: 4,
    name: "Shayan baig",
    position: "HR manager",
    imgSrc: TeamImg,
    socialLinks: {
      google: GoogleIcon,
      instagram: InstagramIcon,
      linkedin: LinkedinIcon,
    },
  },
  {
    id: 5,
    name: "Sofia",
    position: "Graphics Designer",
    imgSrc: TeamImgTwo,
    socialLinks: {
      google: GoogleIcon,
      instagram: InstagramIcon,
      linkedin: LinkedinIcon,
    },
  },
  {
    id: 6,
    name: "Saad",
    position: "Developer",
    imgSrc: TeamImg,
    socialLinks: {
      google: GoogleIcon,
      instagram: InstagramIcon,
      linkedin: LinkedinIcon,
    },
  },
];

const Card = ({ id, name, position, imgSrc, socialLinks }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/team-details/${id}`); // Navigate to the details page with the team member's id
  };

  return (
    <PinContainer title={name} click={handleCardClick}>
      <div className="relative z-20 flex h-full cursor-pointer flex-col items-start overflow-hidden rounded-3xl border border-[#d4d4d491] bg-gradient-box p-3 shadow-custom-box">
        <div className="mb-4 h-[300px] w-full overflow-hidden rounded-2xl">
          <img
            src={imgSrc}
            alt={`${name} - ${position}`}
            width={"100%"}
            height={"100%"}
            className="object-cover object-center"
          />
        </div>
        <div className="flex w-full items-center justify-between rounded-full border border-[#d4d4d491] bg-white px-4 py-2 shadow-custom-box">
          <div>
            <h4 className="xl:text-lg md:text-base text-sm font-semibold tracking-tight text-custom-dark-blue">
              {name}
            </h4>
            <p className="sm:text-sm text-xs text-gray-600">{position}</p>
          </div>
          <ul className="flex items-center gap-x-2 p-0">
            <li className="flex h-[35px] w-[35px] items-center justify-center rounded-full border border-[#d4d4d491]">
              <img src={socialLinks.instagram} alt="Instagram" width={"55%"} />
            </li>
            <li className="flex h-[35px] w-[35px] items-center justify-center rounded-full border border-[#d4d4d491]">
              <img src={socialLinks.linkedin} alt="LinkedIn" width={"55%"} />
            </li>
          </ul>
        </div>
      </div>
    </PinContainer>
  );
};

const Teams = () => {
  return (
    <Container className="relative">
      <div className="head">
        <h2 className="mb-6 text-2xl lg:text-3xl font-semibold tracking-tight text-custom-dark-blue">
          Our Core{" "}
          <span className="relative whitespace-nowrap">
            <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-300" />
            <span className="fontSuse relative text-custom-orange">
              Team Members
            </span>
          </span>
        </h2>
        <p className="max-w-[800px] text-sm md:text-base text-gray-600">
          Lift is a full-service, award-winning digital agency with expertise in
          brand & digital strategy, design, and user experience
        </p>
      </div>
      <Swiper
        slidesPerView={1.1}
        spaceBetween={10}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          600: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 10,
          },
          1300: {
            slidesPerView: 3.2,
            spaceBetween: 20,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Navigation, Autoplay]}
        className="mySwiper teamSwiper"
      >
        {teamMembers.map((member) => (
          <SwiperSlide key={member}>
            <div key={member.id} className="">
              <Card
                id={member.id}
                name={member.name}
                position={member.position}
                imgSrc={member.imgSrc}
                socialLinks={member.socialLinks}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div>
        <ArrowLeftIcon className="swiper-button-prev" />
        <ArrowRightIcon className="swiper-button-next" />
      </div>
    </Container>
  );
};

export default Teams;
