import { useParams } from "react-router-dom";
import TeamImg from "../../assets/team.png";
import TeamImgTwo from "../../assets/team2.jpg";
import TeamImgThree from "../../assets/background-faqs.jpg";
import { HomeIcon } from "@heroicons/react/20/solid";
import { Container } from "../../components/common/Container";
import GoogleIcon from "../../assets/google.svg";
import InstagramIcon from "../../assets/instagram.svg";
import LinkedinIcon from "../../assets/linkedin.svg";
import WhatsappIcon from "../../assets/whatsapp.svg";
import GmailIcon from "../../assets/gmail.svg";

const pages = [
  { name: "Teams", href: "#", current: false },
  { name: "Team Details", href: "#", current: true },
];
// Example data for team members (you can replace this with a dynamic data source)
const teamDetails = {
  1: {
    name: "Rifaqat Ali Khan",
    position: "CEO",
    imgSrc: TeamImg,
    bio: "Rifaqat Ali Khan is a visionary leader with over a decade of experience in guiding companies to success. As the CEO, he brings a wealth of knowledge in business strategy, innovation, and leadership. Under his leadership, the company has experienced unprecedented growth, thanks to his ability to foresee industry trends and adapt to the ever-changing landscape of the business world. His strong communication skills and team-first mentality ensure that every department works cohesively toward common goals. Rifaqat is passionate about fostering a positive work environment, encouraging creativity, and implementing cutting-edge solutions that keep the company ahead of the competition. His background includes years of experience working in both startups and well-established companies, where he honed his ability to transform ideas into profitable ventures.",
    email: "hubbeconnect7@gmail.com",
    phone: "(+92) 3400180008",
    address: "Gilgit, Pakistan",
    age: "22",
  },
  2: {
    name: "Sarah Connor",
    position: "Graphics Designer",
    imgSrc: TeamImgTwo,
    bio: "Sarah Connor is an award-winning graphics designer known for her innovative designs and creative approach to visual storytelling. With a strong background in both digital and print media, Sarah has helped shape the visual identity of numerous brands, delivering work that not only looks great but also drives engagement. Her expertise lies in understanding the client's vision and translating it into compelling visuals that resonate with the target audience. Sarah's ability to balance creativity with practicality has earned her the respect of colleagues and clients alike. She stays up-to-date with the latest design trends and tools, ensuring that her designs are always fresh and relevant. Outside of her professional career, Sarah is passionate about photography and often draws inspiration from nature for her designs.",
    email: "hubbeconnect7@gmail.com",
    phone: "(+92) 3400180008",
    address: "Gilgit, Pakistan",
    age: "22",
  },
  3: {
    name: "John Doe",
    position: "Developer",
    imgSrc: TeamImg,
    bio: "John Doe is a highly skilled full-stack developer with extensive knowledge in front-end and back-end technologies. His expertise includes React, Node.js, and database management, making him a versatile asset to any development team. John has a deep understanding of the software development lifecycle, from initial concept to deployment, and has successfully led numerous projects that have greatly improved business efficiency. His attention to detail and passion for coding ensure that the products he develops are both functional and user-friendly. John is also known for his ability to solve complex problems quickly, often going above and beyond to ensure project deadlines are met without sacrificing quality. In his free time, John enjoys contributing to open-source projects and mentoring junior developers.",
    email: "hubbeconnect7@gmail.com",
    phone: "(+92) 3400180008",
    address: "Gilgit, Pakistan",
    age: "22",
  },
};

function TeamDetails() {
  const { id } = useParams(); // Get the id from the URL
  const teamMember = teamDetails[id]; // Retrieve team member details

  if (!teamMember) {
    return <p>Team member not found</p>;
  }

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${TeamImgThree})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="pb-16 pt-32 md:pb-40 md:pt-52"
      >
        <Container>
          <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-1">
              <li>
                <div>
                  <a
                    href="#"
                    className="text-custom-black hover:text-custom-black/85"
                  >
                    <HomeIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              {pages?.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0 text-custom-black"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <a
                      href={page.href}
                      aria-current={page.current ? "page" : undefined}
                      className="lg:font-base ml-1 text-xs text-custom-black hover:text-custom-black/85 sm:text-sm"
                    >
                      {page.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <h1 className="mt-4 text-xl font-bold text-custom-black sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            Team details
          </h1>
        </Container>
      </div>
      <Container>
        <div
          className={
            "mb-52 mt-20 rounded-2xl border border-[#d4d4d491] bg-gradient-box p-4 shadow-custom-box sm:p-6 lg:p-8"
          }
        >
          <div className="mb-8">
            <div className="mb-6 block w-[100%] max-w-[300px] overflow-hidden sm:h-[350px] md:hidden">
              <img
                src={teamMember.imgSrc}
                alt="img not found"
                width={"100%"}
                height={"100%"}
                className="rounded-2xl object-cover shadow-lg"
              />
            </div>
            <h2 className="mb-4 text-2xl font-semibold tracking-tight text-custom-dark-blue lg:text-3xl">
              {teamMember.name}
            </h2>
            <p className="text-sm text-gray-600 md:text-base">
              {teamMember.bio}
            </p>
          </div>
          <div className="flex flex-col items-center md:flex-row">
            <div className="mb-6 hidden w-[300px] overflow-hidden rounded-2xl md:mb-0 md:mr-10 md:block md:h-[350px] lg:mr-20">
              <img
                src={teamMember.imgSrc}
                alt="img not found"
                width={"100%"}
                height={"100%"}
                className="rounded-2xl object-cover shadow-lg"
              />
            </div>
            <div className="w-full flex-1">
              <ul>
                <li className="mb-4 flex flex-wrap items-center sm:flex-nowrap">
                  <h6 className="w-[90px] text-sm font-medium uppercase text-custom-dark-blue sm:w-[120px] sm:text-base xl:w-[140px]">
                    Position:
                  </h6>{" "}
                  <a
                    href="#"
                    className="text-sm text-orange-500 hover:underline sm:text-base"
                  >
                    {teamMember.position}
                  </a>
                </li>
                <li className="mb-4 flex flex-wrap items-center sm:flex-nowrap">
                  <h6 className="w-[90px] text-sm font-medium uppercase text-custom-dark-blue sm:w-[120px] sm:text-base xl:w-[140px]">
                    Email:
                  </h6>{" "}
                  <a
                    href="#"
                    className="text-sm text-orange-500 hover:underline sm:text-base"
                  >
                    {teamMember.email}
                  </a>
                </li>
                <li className="mb-4 flex flex-wrap items-center sm:flex-nowrap">
                  <h6 className="w-[90px] text-sm font-medium uppercase text-custom-dark-blue sm:w-[120px] sm:text-base xl:w-[140px]">
                    Phone:
                  </h6>{" "}
                  <a
                    href="#"
                    className="text-sm text-orange-500 hover:underline sm:text-base"
                  >
                    {teamMember.phone}
                  </a>
                </li>
                <li className="mb-4 flex flex-wrap items-center sm:flex-nowrap">
                  <h6 className="w-[90px] text-sm font-medium uppercase text-custom-dark-blue sm:w-[120px] sm:text-base xl:w-[140px]">
                    Address:
                  </h6>{" "}
                  <a
                    href="#"
                    className="text-sm text-orange-500 hover:underline sm:text-base"
                  >
                    {teamMember.address}
                  </a>
                </li>
                <li className="mb-4 flex flex-wrap items-center sm:flex-nowrap">
                  <h6 className="w-[90px] text-sm font-medium uppercase text-custom-dark-blue sm:w-[120px] sm:text-base xl:w-[140px]">
                    Age:
                  </h6>{" "}
                  <a
                    href="#"
                    className="text-sm text-orange-500 hover:underline sm:text-base"
                  >
                    {teamMember.age}
                  </a>
                </li>
              </ul>
              <ul className="mt-6 flex items-center gap-x-3 p-0">
                <li className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-lg border border-[#d4d4d491] bg-gradient-box shadow-custom-box transition duration-300 hover:-translate-y-1">
                  <img src={GmailIcon} alt="Google" width={"50%"} />
                </li>
                <li className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-lg border border-[#d4d4d491] bg-gradient-box shadow-custom-box transition duration-300 hover:-translate-y-1">
                  <img src={InstagramIcon} alt="Instagram" width={"50%"} />
                </li>
                <li className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-lg border border-[#d4d4d491] bg-gradient-box shadow-custom-box transition duration-300 hover:-translate-y-1">
                  <img src={LinkedinIcon} alt="LinkedIn" width={"50%"} />
                </li>
                <li className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-lg border border-[#d4d4d491] bg-gradient-box shadow-custom-box transition duration-300 hover:-translate-y-1">
                  <img src={WhatsappIcon} alt="LinkedIn" width={"50%"} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default TeamDetails;
