import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "./common/Container";
import SwirlyDoodle from "./common/SwirlyDoodle";
import WebImg from "../assets/web.png";
import { Button } from "./common/Button";

const Card = ({ onMouseEnter, onMouseLeave, title, description, icon, id }) => {
  return (
    <Link to={`/service-details/${id}`}>
      <div
        className="items- relative z-20 flex h-full cursor-pointer flex-col items-start justify-between overflow-hidden rounded-2xl border border-[#d4d4d491] bg-gradient-box px-6 py-6 shadow-custom-box"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="mb-12 flex w-full items-center justify-between xl:mb-20">
          <span className="flex h-[70px] w-[70px] items-center justify-center rounded-md">
            <img
              src={icon}
              alt="img not found"
              width={"100%"}
              className="filter-orange"
            />
          </span>
        </div>
        <div>
          <h4 className="mb-2 text-lg font-semibold tracking-tight text-custom-dark-blue md:text-xl xl:text-2xl">
            {title}
          </h4>
          <p className="mb-4 text-xs text-gray-600 md:text-sm">{description}</p>
          <Button
            className={
              "border border-custom-dark-blue bg-transparent text-xs text-custom-dark-blue"
            }
          >
            Discover More
          </Button>
        </div>
      </div>
    </Link>
  );
};

const Services = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const containerRef = useRef(null);
  const backgroundRef = useRef(null);

  const handleMouseEnter = (index, event) => {
    setHoveredCard(index);

    const card = event.currentTarget;
    const rect = card.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    // Set the position of the background box relative to the container
    backgroundRef.current.style.transform = `translate3d(${rect.left - containerRect.left}px, ${rect.top - containerRect.top}px, 0)`;
    backgroundRef.current.style.width = `${rect.width}px`;
    backgroundRef.current.style.height = `${rect.height}px`;
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const cards = [
    {
      id: "digital-soft-skills-development",
      title: "Digital Soft Skills Development",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestia",
      icon: WebImg,
    },
    {
      id: "freelancing-skills-workshops",
      title: "Freelancing Skills Workshops",
      description:
        "Create responsive and high-performance websites tailored to your business needs.",
      icon: WebImg,
    },
    {
      id: "career-coaching-and-mentoring",
      title: "Career Coaching and Mentoring",
      description:
        "Design intuitive and engaging user interfaces that enhance user experience.",
      icon: WebImg,
    },
    {
      id: "custom-training-solutions",
      title: "Custom Training Solutions",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. veniam consectetur!",
      icon: WebImg,
    },
  ];

  useEffect(() => {
    // Reset the background box position and size on mount
    if (backgroundRef.current) {
      backgroundRef.current.style.transform = "translate3d(0, 0, 0)";
      backgroundRef.current.style.width = "0";
      backgroundRef.current.style.height = "0";
    }
  }, []);

  return (
    <Container>
      <div className="head mb-10 md:mb-20">
        <h2 className="mb-6 text-2xl font-semibold tracking-tight text-custom-dark-blue lg:text-3xl">
          Our{" "}
          <span className="relative whitespace-nowrap">
            <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-300" />
            <span className="fontSuse relative text-custom-orange">
              Services
            </span>
          </span>
        </h2>
        <p className="max-w-[800px] text-sm text-gray-600 md:text-base">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias
          nemo cum culpa dolores blanditiis porro provident veniam consectetur!
          Natus ratione vel a nostrum perspiciatis quibusdam dolorem doloribus
          autem deleniti aliquam?
        </p>
      </div>

      <div className="relative" ref={containerRef}>
        <div
          ref={backgroundRef}
          className="absolute left-0 top-0 z-10 rounded-2xl bg-white transition-all duration-300 ease-in-out"
        ></div>

        <div className="relative z-10 flex flex-wrap">
          {cards.map((card, index) => (
            <div
              className="w-full py-3 sm:w-[50%] sm:p-3 lg:w-[33.33%] xl:p-4"
              key={index}
              onMouseEnter={(event) => handleMouseEnter(index, event)}
              onMouseLeave={handleMouseLeave}
            >
              <Card
                id={card.id}
                title={card.title}
                description={card.description}
                icon={card.icon}
              />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Services;
