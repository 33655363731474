import React from "react";
import { Container } from "./common/Container";
import SwirlyDoodle from "./common/SwirlyDoodle";
import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

import ContactImg from "../assets/contact.png";

const Contact = () => {
  return (
    <Container>
      <div className="head md:mb-20 mb-10">
        <h2 className=" text-custom-dark-blue mb-4 sm:mb-6 text-2xl lg:text-3xl font-semibold tracking-tight">
          Feel free to{" "}
          <span className="relative whitespace-nowrap">
            <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-300" />
            <span className="fontSuse text-custom-orange relative">
              get in touch!
            </span>
          </span>
        </h2>
        <p className="max-w-[800px] text-sm md:text-base text-gray-600">
          Let’s talk about a project, collaboration or an idea you may have
        </p>
        <div className="mt-12 flex justify-between flex-wrap">
          <div className="flex lg:w-[40%] mb-4 sm:mb-6 lg:mb-0 w-full items-center">
            <span className="shadow-custom-box bg-gradient-box mr-4 flex md:h-[70px] md:w-[70px] w-[50px] h-[50px] items-center justify-center rounded-lg border border-[#d4d4d491]">
              <EnvelopeIcon className="text-custom-orange w-6 md:w-8" />
            </span>
            <div className="flex-1">
              <h4 className="text-custom-dark-blue fontSuse text-sm font-semibold uppercase">
                E-mail
              </h4>
              <a href="#" className="text-gray-600 text-sm">
                hubbeconnect7@gmail.com
              </a>
            </div>
          </div>
          <div className="flex lg:w-[30%] sm:w-[48%] w-full mb-4 sm:mb-0 items-center">
            <span className="shadow-custom-box bg-gradient-box mr-4 flex md:h-[70px] md:w-[70px] w-[50px] h-[50px] items-center justify-center rounded-lg border border-[#d4d4d491]">
              <PhoneIcon className="text-custom-orange w-6 md:w-8" />
            </span>
            <div className="flex-1">
              <h4 className="text-custom-dark-blue fontSuse text-sm font-semibold uppercase">
                Phone
              </h4>
              <a href="#" className="text-gray-600 text-sm">
                +92 3400180008
              </a>
            </div>
          </div>
          <div className="flex lg:w-[30%] sm:w-[48%] w-full items-center">
            <span className="shadow-custom-box bg-gradient-box mr-4 flex md:h-[70px] md:w-[70px] w-[50px] h-[50px] items-center justify-center rounded-lg border border-[#d4d4d491]">
              <MapPinIcon className="text-custom-orange w-6 md:w-8" />
            </span>
            <div className="flex-1">
              <h4 className="text-custom-dark-blue fontSuse text-sm font-semibold uppercase">
                Address
              </h4>
              <a href="#" className="text-gray-600 text-sm">
                Lower chitral, pakistan
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-custom-box bg-gradient-box flex items-center rounded-2xl border border-[#d4d4d491] md:p-8 sm:p-6 p-4">
        <form className="2xl:mr-20 md:mr-4 lg:w-[500px] md:w-[60%] w-full">
          <div className="flex flex-col sm:flex-row sm:gap-x-6 gap-x-4">
            <input
              type="text"
              id="name"
              placeholder="Name"
              className="focus:ring-custom-orange text-custom-black fontSuse mb-4 sm:mb-6 w-full rounded-md bg-white px-4 py-3 text-sm outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2"
            />
            <input
              type="text"
              id="phone"
              placeholder="Phone"
              className="focus:ring-custom-orange text-custom-black fontSuse mb-4 sm:mb-6 w-full rounded-md bg-white px-4 py-3 text-sm outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2"
            />
          </div>
          <input
            type="email"
            id="email"
            placeholder="Email"
            className="focus:ring-custom-orange text-custom-black fontSuse mb-4 sm:mb-6 w-full rounded-md bg-white px-4 py-3 text-sm outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2"
          />
          <input
            type="text"
            id="subject"
            placeholder="Subject"
            className="focus:ring-custom-orange text-custom-black fontSuse mb-4 sm:mb-6 w-full rounded-md bg-white px-4 py-3 text-sm outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2"
          />
          <textarea
            id="message"
            placeholder="Message"
            className="resize-x-none focus:ring-custom-orange text-custom-black fontSuse mb-4 sm:mb-6 min-h-[100px] w-full rounded-md bg-white px-4 py-3 text-sm outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2"
          ></textarea>
        </form>
        <div className="flex-1 md:block hidden">
          <div className="mx-auto xl:w-[70%] w-full">
            <img src={ContactImg} alt="contact us" width={"100%"} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
