import { useParams } from "react-router-dom";
import DemoImg from "../../assets/demo.avif";
import DemoImgTwo from "../../assets/demo2.jfif";
import DemoImgThree from "../../assets/demo3.jfif";
import { Container } from "../../components/common/Container";
import { HomeIcon } from "@heroicons/react/20/solid";
import SwirlyDoodle from "../../components/common/SwirlyDoodle";
import Contact from "../../components/contact";
import { useEffect } from "react";

const pages = [
  { name: "Service", href: "#", current: false },
  { name: "Services Details", href: "#", current: true },
];

// Service data based on ID
const serviceDetails = {
  "digital-soft-skills-development": {
    title: "Digital Soft Skills Development",
    content:
      "Training in key soft skills such as communication, time management, and project management, which are crucial for freelancing success.",
  },
  "freelancing-skills-workshops": {
    title: "Freelancing Skills Workshops",
    content:
      "Interactive workshops focused on various freelancing skills, providing practical knowledge and hands-on experience.",
  },
  "career-coaching-and-mentoring": {
    title: "Career Coaching and Mentoring",
    content:
      "Personalized guidance and support to help individuals navigate their freelancing careers, set goals, and achieve their professional aspirations.",
  },
  "custom-training-solutions": {
    title: "Custom Training Solutions",
    content:
      "Tailored training programs designed to meet the specific needs of businesses and individuals, ensuring relevant and effective skill development.",
  },
};

function ServiceDetails() {
  const { id } = useParams();
  const service = serviceDetails[id];
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on load
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(20deg, #082635, rgba(0,0,0,0.1)), url(${DemoImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="pb-24 pt-40 md:pb-52 md:pt-52"
      >
        <Container>
          <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-1">
              <li>
                <div>
                  <a href="#" className="text-white hover:text-white/85">
                    <HomeIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              {pages?.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0 text-white"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <a
                      href={page.href}
                      aria-current={page.current ? "page" : undefined}
                      className="lg:font-base ml-1 text-xs text-white hover:text-white/85 sm:text-sm"
                    >
                      {page.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <h1 className="mt-4 text-xl font-bold text-white sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            {service.title}
          </h1>
        </Container>
      </div>
      <div className="py-10 md:py-20">
        <Container>
          <div className="head mb-10 md:mb-20">
            <h2 className="mb-6 text-2xl font-semibold tracking-tight text-custom-dark-blue lg:text-3xl">
              Service{" "}
              <span className="relative whitespace-nowrap">
                <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-300" />
                <span className="fontSuse relative text-custom-orange">
                  details
                </span>
              </span>
            </h2>
            <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
              {service.content}
            </p>
            <p className="text-xs text-gray-600 sm:text-sm md:text-base">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Animi
              labore in esse perferendis rem modi ipsum quae similique incidunt
              perspiciatis maiores dicta corporis minima quis quas sit
              consequuntur, quibusdam quos praesentium quisquam nulla culpa
              officia quidem? Dolorum, cumque ipsam quia quibusdam non
              voluptatem quaerat sapiente reprehenderit. Dignissimos tempore
              vero sit perspiciatis? Similique qui, eos fugit iusto quis
              consectetur velit id, fuga dolorum suscipit tempora ullam illum
              perferendis! Nam voluptatum, odio recusandae eveniet illo ad rerum
              qui quia et,
            </p>
          </div>
          <div className="my-12 flex flex-wrap items-center justify-between">
            <div className="mx-auto mb-6 w-full sm:mb-0 sm:w-[62%]">
              <img
                src={DemoImgTwo}
                alt=""
                width={"100%"}
                height={"100%"}
                className="rounded-2xl object-cover shadow-lg"
              />
            </div>
            <div className="mx-auto w-full sm:w-[30%]">
              <img
                src={DemoImgThree}
                alt=""
                width={"100%"}
                height={"100%"}
                className="rounded-2xl object-cover shadow-lg"
              />
            </div>
          </div>
        </Container>
      </div>

      <section className="pb-52">
        <Contact />
      </section>
    </div>
  );
}

export default ServiceDetails;
