import React from "react";
import { Container } from "./common/Container";
import Logo from "../assets/white logo.svg";
import GoogleIcon from "../assets/google.svg";
import InstagramIcon from "../assets/instagram.svg";
import LinkedinIcon from "../assets/linkedin.svg";
import WhatsappIcon from "../assets/whatsapp.svg";
import GmailIcon from "../assets/gmail.svg";
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";
import {
  FaHome,
  FaBoxOpen,
  FaServicestack,
  FaPhoneAlt,
  FaGithub,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="relative bg-custom-dark-blue pt-20">
        <div className="zx">
          <div class="custom-shape-divider-bottom-1725395112">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                class="shape-fill"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                class="shape-fill"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </div>
        <Container
          className={
            "flex flex-wrap items-start justify-between pb-10 sm:pb-20 md:flex-nowrap"
          }
        >
          <div className="mb-6 w-full md:mb-0 md:mr-8 md:w-[450px]">
            <img
              src={Logo}
              alt="logo not found"
              className="w-[160px] sm:w-[260px]"
            />
            <p className="mb-4 mt-4 max-w-[400px] text-sm tracking-wide text-white">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores,
              minus? Lorem ipsum dolor sit.
            </p>
            <ul className="mt-6 flex items-center gap-x-2 p-0">
              <li className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300">
                <img src={GmailIcon} alt="Google" width={"50%"} />
              </li>
              <li className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300">
                <img src={InstagramIcon} alt="Instagram" width={"50%"} />
              </li>
              <li className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300">
                <img src={LinkedinIcon} alt="LinkedIn" width={"50%"} />
              </li>
              <li className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300">
                <img src={WhatsappIcon} alt="LinkedIn" width={"50%"} />
              </li>
            </ul>
          </div>

          <ul className="mb-4 w-full pt-2 sm:mb-0 sm:w-[150px]">
            <h3 className="mb-6 sm:text-xl text-lg font-medium text-white">Discover</h3>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">About us</a>
            </li>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">Services</a>
            </li>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">Our team</a>
            </li>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">Contact us</a>
            </li>
          </ul>

          <ul className="flex-1 pt-2 xl:max-w-[400px]">
            <h3 className="mb-4 sm:text-xl text-lg font-medium text-white">
              Contact information
            </h3>
            <li className="mb-4 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#" className="flex items-center">
                <PhoneIcon className="mr-4 w-6 text-custom-orange" />
                <span className="flex-1">(+92) 3400180008</span>
              </a>
            </li>
            <li className="mb-4 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#" className="flex items-center">
                <EnvelopeIcon className="mr-4 w-6 text-custom-orange" />
                <span className="flex-1">hubbeconnect7@gmail.com</span>
              </a>
            </li>
            <li className="mb-4 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#" className="flex items-center">
                <MapPinIcon className="mr-4 w-6 text-custom-orange" />
                <span className="flex-1">
                  Hub Be Connect, Garamcashma Road Balach payeen, lower chitral,
                  pakistan
                </span>
              </a>
            </li>
          </ul>
        </Container>
        <div className="after:content-[' '] relative py-6 after:absolute after:left-[10%] after:top-0 after:w-[80%] after:border after:border-gray-500">
          <Container>
            <p className="text-center text-xs text-white">
              2024 © HUB BE CONNECT SMC (PVT) LTD. All rights reserved.
            </p>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Footer;
