import React from "react";
import { Container } from "./common/Container";
import { Button } from "./common/Button";

import AboutImg from "../assets/about.webp";
import SwirlyDoodle from "./common/SwirlyDoodle";

const AboutSection = () => {
  return (
    <>
      <Container>
        <div className="head mb-10 md:mb-20">
          <h2 className="mb-6 text-2xl font-semibold tracking-tight text-custom-dark-blue lg:text-3xl">
            We are digital creative{" "}
            <span className="relative whitespace-nowrap">
              <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-300" />
              <span className="fontSuse relative text-custom-orange">
                agency in Chitral
              </span>
            </span>
          </h2>
          <p className="mb-4 text-xs sm:text-sm text-gray-600 md:text-base">
            Hub Be-Connect is a start-up tech company in the Chitral District.
            Since the company stands on the notion of digital skills as an
            academic initiative, we are offering short courses & diploma
            courses.
          </p>
          <p className="mb-4 text-xs sm:text-sm text-gray-600 md:text-base">
            Hub Beconnect is dedicated to equipping individuals and
            organizations with the vital freelancing skills necessary to thrive
            in the modern digital landscape. Founded on the principles of
            innovation, excellence, and community, we provide top-tier training
            in essential areas such as bookkeeping and graphic designing.
          </p>
          <p className="mb-4 text-xs sm:text-sm text-gray-600 md:text-base">
            At Hub Beconnect, we are committed to exceptional service and
            integrity.
          </p>
          <p className="mb-4 text-xs sm:text-sm text-gray-600 md:text-base">
            Our team of experienced professionals understands the unique needs
            of our clients, offering tailored solutions that help them navigate
            complexity, seize opportunities, and excel in their careers. By
            fostering a supportive community of skilled professionals, we enable
            collaboration, knowledge sharing, and mutual growth.
          </p>
          <p className="text-xs sm:text-sm text-gray-600 md:text-base">
            Join us at Hub Beconnect and take the next step in your professional
            journey. Together, we can achieve excellence and strategic success.
          </p>
        </div>

        <div className="after:content-[' '] relative flex flex-wrap items-end pb-10 after:absolute after:bottom-0 after:left-[15%] after:h-[2px] after:w-[70%] after:border after:border-dashed after:border-[#d4d4d491]">
          <div className="mb-6 w-full sm:mb-0 sm:flex-1">
            <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
              01
            </span>
            <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
              Our <span className="fontSuse text-custom-orange">vision</span>
            </h3>
            <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laboriosam quia quas eligendi delectus? Minus illum veritatis
              dignissimos quis culpa necessitatibus eaque iure officia tempore
              quasi similique facilis praesentium, autem illo?
            </p>
          </div>
          <div className="ml-auto w-full overflow-hidden rounded-lg border border-[#d4d4d491] shadow-custom-box sm:ml-4 sm:w-[220px] md:ml-8 md:w-[300px] xl:ml-14">
            <img
              src={AboutImg}
              alt="img not found"
              width={"100%"}
              className="rounded-md object-cover object-center"
            />
          </div>
        </div>

        <div className="after:content-[' '] relative mt-10 flex flex-wrap items-end pb-10 after:absolute after:bottom-0 after:left-[15%] after:h-[2px] after:w-[70%] after:border after:border-dashed after:border-[#d4d4d491]">
          <div className="order-2 ml-auto w-full overflow-hidden rounded-lg border border-[#d4d4d491] shadow-custom-box sm:order-1 sm:mr-4 sm:w-[220px] md:mr-8 md:w-[300px] xl:mr-14">
            <img
              src={AboutImg}
              alt="img not found"
              width={"100%"}
              className="rounded-md object-cover object-center"
            />
          </div>
          <div className="order-1 mb-6 w-full sm:order-2 sm:mb-0 sm:flex-1">
            <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
              02
            </span>
            <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
              Our <span className="fontSuse text-custom-orange">mission</span>
            </h3>
            <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laboriosam quia quas eligendi delectus? Minus illum veritatis
              dignissimos quis culpa necessitatibus eaque iure officia tempore
              quasi similique facilis praesentium, autem illo?
            </p>
          </div>
        </div>

        <div className="after:content-[' '] relative mt-10 flex flex-wrap items-end pb-10 after:absolute after:bottom-0 after:left-[15%] after:h-[2px] after:w-[70%] after:border after:border-dashed after:border-[#d4d4d491]">
          <div className="mb-6 w-full sm:mb-0 sm:flex-1">
            <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
              03
            </span>
            <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
              Our <span className="fontSuse text-custom-orange">goal</span>
            </h3>
            <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laboriosam quia quas eligendi delectus? Minus illum veritatis
              dignissimos quis culpa necessitatibus eaque iure officia tempore
              quasi similique facilis praesentium, autem illo?
            </p>
          </div>
          <div className="ml-auto w-full overflow-hidden rounded-lg border border-[#d4d4d491] shadow-custom-box sm:ml-4 sm:w-[220px] md:ml-8 md:w-[300px] xl:ml-14">
            <img
              src={AboutImg}
              alt="img not found"
              width={"100%"}
              className="rounded-md object-cover object-center"
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default AboutSection;
