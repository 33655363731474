import clsx from 'clsx'

export function Container({ className, ...props }) {
  return (
    <div
      className={clsx('mx-auto w-full lg:w-[90%] xl:w-[80%] max-w-7xl px-4 md:px-8 sm:px-6 lg:px-8', className)}
      {...props}
    />
  )
}
