import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { Container } from "./common/Container";
import SwirlyDoodle from "./common/SwirlyDoodle";
import { Link } from "react-router-dom";
import {
  ArrowUpRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";

const skills = [
  { name: "Video Editing", id: "video-editing" },
  { name: "Graphic Designing", id: "graphic-designing" },
  { name: "Content Writing", id: "content-writing" },
  { name: "Business Communication", id: "business-communication" },
  { name: "Stress Management", id: "stress-management" },
  { name: "Interpersonal Soft Skill", id: "interpersonal-soft-skill" },
  {
    name: "English for Business and Entrepreneurs",
    id: "english-for-business-and-entrepreneurs",
  },
  {
    name: "Health Safety and Environment",
    id: "health-safety-and-environment",
  },
  { name: "Trading Financial Markets", id: "trading-financial-markets" },
  { name: "Professional Graphic Design", id: "professional-graphic-design" },
  { name: "Web Development", id: "web-development" },
  { name: "Application Development", id: "application-development" },
  { name: "Hotel Management Essentials", id: "hotel-management-essentials" },
  {
    name: "Tourism Development & Management",
    id: "tourism-development-management",
  },
  { name: "Educational Leadership", id: "educational-leadership" },
  { name: "MS Office Basic Course", id: "ms-office-basic-course" },
  { name: "Freelancing for Beginners", id: "freelancing-for-beginners" },
];

const Card = ({ item }) => {
  return (
    <>
      <Link to={`/course-details/${item.id}`}>
        <div className="relative flex h-[100px] cursor-pointer items-center justify-between overflow-hidden rounded-lg border-2 border-[#d4d4d491] bg-gradient-box px-4 py-6 shadow-custom-box transition duration-300 hover:border-custom-orange">
          <h4 className="flex-1 text-base font-semibold tracking-tight text-custom-dark-blue">
            {item?.name}
          </h4>
          <ArrowUpRightIcon className="ml-2 w-6" />
        </div>
      </Link>
    </>
  );
};

const Courses = () => {
  return (
    <Container className={"relative"}>
      <div className="head">
        <h2 className="mb-6 text-2xl font-semibold tracking-tight text-custom-dark-blue lg:text-3xl">
          What We Offer{" "}
          <span className="relative whitespace-nowrap">
            <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-300" />
            <span className="fontSuse relative text-custom-orange">
              For You
            </span>
          </span>
        </h2>
        <p className="max-w-[800px] text-sm text-gray-600 md:text-base">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse
          asperiores eos pariatur, rem ratione exercitationem facilis modi ipsum
          delectus nostrum iusto beatae minus est natus mollitia, aperiam
          voluptas neque sed?
        </p>
      </div>
      <Swiper
        slidesPerView={1.2}
        spaceBetween={10}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          500: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3.2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4.2,
            spaceBetween: 20,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next-course",
          prevEl: ".swiper-button-prev-course",
        }}
        modules={[Navigation, Autoplay]}
        className="mySwipercourses coursesSwiper"
      >
        {skills?.map((skill) => (
          <SwiperSlide key={skill}>
            <Card item={skill} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex">
        <ArrowLeftIcon className="swiper-button-prev-course" />
        <ArrowRightIcon className="swiper-button-next-course" />
      </div>
    </Container>
  );
};

export default Courses;
