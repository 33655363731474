import clsx from 'clsx';

export function Avatar({ className, imgSrc, width = 50, height = 50, ...props }) {
  return (
    <div
      className={clsx('rounded-full overflow-hidden', className)}
      style={{ width: `${width}px`, height: `${height}px` }}
      {...props}
    >
      <img
        src={imgSrc}
        alt="Avatar"
        className="object-cover"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}
