import clsx from 'clsx'

export function Button({ className, ...props }) {
  return (
    <button
      className={clsx('inline-block rounded-full md:px-6 px-4 py-2 transition-all fontSuse', className)}
      {...props}
    />
  )
}
